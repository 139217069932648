import md5 from 'js-md5';
import { nanoid } from 'nanoid';

class YorkbbsSign {
  constructor(appkey, params,uuid) {
    /* 公钥 */
    this.appkey = appkey;
    this.params = params || {};
    //客户端指纹
    this.uuid = uuid;
  }

  /* 随机字符串 */
  getRandomStr(length = 32) {
    // const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // const maxPos = chars.length;
    // let str = '';
    // for (let i = 0; i < length; i++) {
    //   str += chars.charAt(Math.floor(Math.random() * maxPos));
    // }
    // return str;
    return nanoid(length);
  }

  /* 时间戳 */
  getTimestamp(date = new Date()) {
    return date.getTime();
  }

  /* 参数排序 */
  paramsSort() {
    return Object.keys(this.params)
      .filter(key => this.params[key] !== undefined && this.params[key] !== null && this.params[key] !== '' && !Number.isNaN(this.params[key]))
      .sort()
      .map(key => {
        const value = this.params[key];
        return `${key}=${typeof value === 'object' ? JSON.stringify(value) : value}`;
      })
      .join('&');
  }

  /* 获取签名 */
  getSignData() {
    const timestamp = this.getTimestamp();
    const nonceStr = this.getRandomStr();
    
    const str = this.paramsSort() + `&appkey=${this.appkey}&timestamp=${timestamp}&nonce-str=${nonceStr}&uuid=${this.uuid}`;

    
    const sign = md5(str).toUpperCase();
    return { timestamp, nonceStr, sign };
  }
}

export { YorkbbsSign };
